import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["cages"]

  connect() {
    this.element[this.identifier] = this
  }

  setup(event) {
    event.preventDefault()

    const splitBy = $('input[name="acuc_cage_split[split_by]"]:checked', this.element).val()
    alert(splitBy)

    return false
  }
}
