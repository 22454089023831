import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "cameras", "location", "modal", "reader", "scans", "start", "stop", "mute", "successAudio", "errorAudio" ]
  static values  = {
    location: Object,
    locationsUrl: String,
    status: String,
    url: String
  }

  connect() {
    this.element[this.identifier] = this

    // Immediately show prompt to ask for permission
    $(this.modalTarget).modal('show')
  }

  beginScanning() {
    Html5Qrcode.getCameras().then(devices => {
      $(this.modalTarget).modal('hide')

      this.camerasTarget.disabled = false
      this.camerasTarget.options.length = 0
      this.startTarget.disabled = false
      this.stopTarget.disabled = false
      this.muted = false

      devices.forEach(device => {
        this.addCameraOption(device)
      })
    }).catch(err => {
      window.toastr.error(err)
    })
  }

  addCameraOption(device) {
    var option = document.createElement('option')
    option.text = device.label
    option.value = device.id
    this.camerasTarget.add(option)
  }

  start() {
    const scanCallback = (decodedText, decodedResult) => {
      this.scan(decodedText, decodedResult)
    }

    $(this.element).addClass('scanning')

    this.html5QrCode = new Html5Qrcode(this.readerTarget.id)
    this.html5QrCode
      .start(this.camerasTarget.value,
             { fps: 10, qrbox: 250 , formatsToSupport: [Html5QrcodeSupportedFormats.QR_CODE], experimentalFeatures: { useBarCodeDetectorIfSupported: true } },
             scanCallback)
      .catch((err) => {
        console.log(err)
      })

    window.setTimeout(()=> { this.locationTarget.scrollIntoView() }, 1000)
  }

  stop() {
    $(this.element).removeClass('scanning')

    if (!this.html5QrCode) { return }

    this.html5QrCode.stop().then((_ignore) => {
      this.readerTarget.innerHTML = ''
      this.html5QrCode = null
    }).catch((err) => {
      console.log(err)
    })
  }

  scan(decodedText, _decodedResult) {
    if (this.lastScan == decodedText) {
      return
    }

    this.lastScan = decodedText

    switch(decodedText.charAt(0)) {
      case 'L':
        this.setLocation(decodedText)
        break
      default:
        this.cageCard(decodedText)
    }
  }

  cageCard(code) {
    if (!this.locationSelected()) {
      window.toastr.error('Scan a location card first...')
      return
    }

    this.addCageToTable(code)
    const controller = this

    $.ajax({
      url: this.urlValue,
      data: { 'code': code, 'location': this.locationId() },
      type: 'POST',
      error: function() {
        controller.statusValue = 'error'
        window.toastr.error('An error occured when recording this scan.')
        controller.codeScanError(code)
      },
      success: function() {
        controller.statusValue = 'valid'
        controller.codeScanSuccess(code)
      }
    })
  }

  setLocation(code) {
    this.locationValue = { code: code }
    this.addLocationToTable(code)

    const controller = this
    const locationUrl = this.locationsUrlValue + '/' + code + '.json'

    $.ajax({
      url: locationUrl,
      type: 'GET',
      error: function() {
        controller.statusValue = 'error'
        window.toastr.error('Location card not found!')
        controller.locationScanError(code)
      },
      success: function(response) {
        controller.locationValue = response
        controller.locationScanSuccess(code, response)
      }
    })
  }

  locationSelected() {
    if (!this.hasLocationValue || Object.keys(this.locationValue).length === 0) {
      return false
    }
    return true
  }

  addLocationToTable(code) {
    var row = $('<tr class="location" id="' + code + '"><td><em>Location set</em></td><td>' + code + '</td><td></td></tr>')
    $('tbody', $(this.scansTarget)).prepend(row)
  }

  addCageToTable(code) {
    var row = $('<tr class="cage" id="' + code + '"><td>' + code + '</td><td>' + this.locationName() + '</td><td></td></tr>')
    $('tbody', $(this.scansTarget)).prepend(row)
  }

  codeScanSuccess(code) {
    this.successAudioTarget.play()
    $('td:last', '#' + code).html('<i class="fa fa-check text-success"></i>')
  }

  codeScanError(code) {
    this.errorAudioTarget.play()
    $('#' + code).addClass('danger')
    $('td:last', '#' + code).html('<i class="fa fa-warning text-danger"></i>')
  }

  locationId() {
    return this.locationValue.id || this.locationValue.code
  }

  locationName() {
    return this.locationValue.name || this.locationValue.code
  }

  locationValueChanged() {
    if (this.locationSelected()) {
      this.statusValue = 'valid'
      $(this.locationTarget).html(this.locationName())
    }
    else {
      $(this.locationTarget).html('Scan a location card...')
    }
  }

  locationScanError(code) {
    this.locationValue = {}
    this.codeScanError(code)
  }

  locationScanSuccess(code, location) {
    $('td:nth-child(2)', $('#' + code)).prepend(location.name + ' - ')
    this.codeScanSuccess(code)
  }

  statusValueChanged() {
    $('.acuc-scanner-group').removeClass (function (_index, className) {
      return (className.match (/(^|\s)is-\S+/g) || []).join(' ');
    });

    switch(this.statusValue) {
      case 'valid':
        $('.acuc-scanner-group', $(this.element)).addClass('is-valid')
        break
      case 'error':
        $('.acuc-scanner-group', $(this.element)).addClass('is-invalid')
        break
    }
  }

  toggleSound() {
    this.muted = !this.muted
    this.successAudioTarget.muted = this.muted
    this.errorAudioTarget.muted = this.muted
    this.muteTarget.innerHTML = this.muted ? '<i class="fa fa-volume-up"></i> Unmute' : '<i class="fa fa-volume-off"></i> Mute'
  }
}
