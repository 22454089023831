import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["categoryIds", "esignature"]
  static values = { categoryId: Number, esignaturePrompt: Boolean }

  connect() {
    this.element[this.identifier] = this

    if (!this.hasCategoryIdsTarget) {
      return;
    }

    // Set the currently selected categoryId and watch for changes to the categoryIds select
    this.categoryIdValue = Number(this.categoryIdsTarget.selectedIndex)
    $(this.categoryIdsTarget).on('change', (e) => {
      this.categoryChanged(e.target.selectedIndex)
    })
  }

  disconnect() {
  }

  // The selected categoryId has changed to the given value
  //
  categoryChanged(selected) {
    this.categoryIdValue = selected
  }

  // The categoryId has changed. If the selected category has the esignature setting of true then prompt the user
  // to answer the esignature question.
  //
  categoryIdValueChanged() {
    if (!this.hasCategoryIdsTarget) {
      return;
    }

    var category = this.categoryIdsTarget.options[this.categoryIdValue]
    this.esignaturePromptValue = category && $(category).data('data') && $(category).data('data').esignature == true
  }

  // Prompt for esignature if the selected category is now a category that can have electronic signatures.
  // See `AttachedFileCategory`
  //
  // Right now, this is IRB specific, check for presence of esignatureTarget and do nothing if not present.
  //
  esignaturePromptValueChanged() {
    if (!this.hasEsignatureTarget) { return }

    if(this.esignaturePromptValue == true) {
      this.esignatureTarget.classList.remove('not-applicable')
      this.esignatureTarget.classList.add('applicable')
    }
    else {
      this.esignatureTarget.classList.remove('applicable')
      this.esignatureTarget.classList.add('not-applicable')
    }
  }
}
