import { Controller } from "stimulus";

export default class extends Controller {
  // static targets = ['modal']

  connect() {
    this.element[this.identifier] = this

    this.boundKeyupListener = this.keyupListener.bind(this)
    this.element.addEventListener('keyup', this.boundKeyupListener)

    this.boundFocusListener = this.focusListener.bind(this)
    $('[role=option]', this.element).attr('tabindex', '-1')
    $('[role=option]', this.element).on('focus', this.boundFocusListener)

    this.selected = null
  }

  focusListener(event) {
    if (this.selected && event.target == this.selected[0]) {
      return
    }

    this.setSelection(event.target)
  }

  keyupListener(event) {
    if (event.keyCode == 38) {
      this.selectHigerItem()
    }
    else if (event.keyCode == 40) {
      this.selectLowerItem()
    }
    else if (event.keyCode == 13) {
      this.select()
    }
    else {
      return
    }

    event.preventDefault()
    event.stopPropagation()
  }

  select() {
    if (!this.selected) {
      return
    }

    $('.btn:first', this.selected).click()
  }

  setSelection(option) {
    this.selected = $(option)
    $(this.element).attr('aria-activedescendant', this.selected.attr('id'))
    $('[role=option]', this.element).removeClass('active')
    $('[role=option]', this.element).attr('aria-selected', 'false')
    this.selected.addClass('active')
    this.selected.attr('aria-selected', 'true')
    this.selected.focus()
  }

  setSelectionToFirstOption() {
    this.setSelection($('[role=option]:first', this.element))
  }

  selectLowerItem() {
    if (this.selected == null) {
      return this.setSelectionToFirstOption()
    }

    var option = $(this.selected).next('[role=option]')[0]
    if (!option) { return }
    this.setSelection(option)
  }

  selectHigerItem() {
    if (this.selected == null) {
      return this.setSelectionToFirstOption()
    }

    var option = $(this.selected).prev('[role=option]')[0]
    if (!option) { return }
    this.setSelection(option)
  }
}
