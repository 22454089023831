import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['modal']

  connect() {
    this.element[this.identifier] = this
    $(this.element).modal('show')
    $(this.element).data('opener', $('#modal-frame').data('opener'))
  }

  hideModal() {
    this.element.parentElement.removeAttribute("src")
    this.element.remove()
  }

  // hide modal on successful form submission
  // action: "turbo:submit-end->turbo-modal#hideOnSuccess"
  hideOnSuccess(e) {
    if (e.detail.success && e.srcElement.method != 'get') { // only hide on non-GET requests
      $(this.element).modal('hide')
    }
  }
}
