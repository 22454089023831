import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.element[this.identifier] = this
    this.element.querySelectorAll('input[type="number"]').forEach((input) => {
      input.addEventListener('change', this.calculate.bind(this))
    })

    this.calculate()
  }

  calculate() {
    const inputs = this.element.querySelectorAll('input[type="number"]')

    const groupedInputs = Array.from(inputs).reduce((acc, input) => {
      var id = input.parentNode.parentNode.id // Get the id of the <tr> element
      if (!acc[id]) { acc[id] = [] }
      acc[id].push(input)
      return acc
    }, {});

    Object.values(groupedInputs).forEach((inputs) => {
      const total = inputs.reduce((acc, input) => {
        return acc + parseInt(input.value || 0)
      }, 0)
      inputs[0].parentNode.parentNode.querySelector('.sub-total').innerText = total
    })

    const inputsGroupedByColumn = Array.from(inputs).reduce((acc, input) => {
      const td = input.parentNode // Get the <td> element
      const columnIndex = Array.from(td.parentNode.children).indexOf(td) // Get the column index of the <td> element

      if (!acc[columnIndex]) { acc[columnIndex] = [] }
      acc[columnIndex].push(input)
      return acc
    }, {});

    Object.entries(inputsGroupedByColumn).forEach(([index, inputs]) => {
      const total = inputs.reduce((acc, input) => {
        return acc + parseInt(input.value || 0)
      }, 0)

      const tfoot = this.element.querySelector('tfoot tr')
      const tdAtIndex = tfoot.children[index]
      tdAtIndex.innerText = total
    })

    const total = Array.from(inputs).reduce((acc, input) => {
      return acc + parseInt(input.value || 0)
    }, 0)
    this.element.querySelector('.grand-total').innerText = total
  }
}
