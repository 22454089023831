if document.querySelector('meta[name="fe-error-log-url"]')
  window.addEventListener 'error', (error) =>
    payload = {
      colno: error.colno
      lineno: error.lineno
      location: window.location.href
      message: error.message
      source: error.filename
      stack: if error.error && error.error.stack then error.error.stack else null
    }
    $.ajax(
      type: 'POST'
      url: document.querySelector('meta[name="fe-error-log-url"]').content
      contentType: 'application/json; charset=utf-8'
      dataType: 'json'
      data: JSON.stringify(payload)
    )
