import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.element[this.identifier] = this
    this.container = this.element.querySelector('.box-body')
    this.count = 0
    document.addEventListener('htmldiff:rendered', (event) => this.render(event))
  }

  disconnect() {
  }

  render(event) {
    let element = event.target
    self = this
    var count = 0
    var diffs = element.querySelectorAll('.diff-nav-this .diff-changed, .diff-nav-this .diff-added, .diff-nav-this .diff-removed')
    if (diffs.length > 0) {
      let header = document.createElement('h4')
      header.innerHTML = element.dataset.title
      this.container.appendChild(header)
      this.element.classList.remove('d-none')
    }
    diffs.forEach(function(diff) {
      self.count += 1
      if (diff.id == '') {
        diff.id = 'diff-' + self.count
      }

      count += 1
      var name = diff.dataset.elementName ||
                 (diff.querySelector('.mtf-el-name') ? diff.querySelector('.mtf-el-name').textContent : null) ||
                 diff.textContent;
      var link = document.createElement('a')
      link.href = '#' + diff.id
      link.innerHTML = count + '. ' + (name.length > 100 ? name.substring(0, 100) + '...' : name);
      link.className = 'mini-list-item'
      link.dataset.turbo = 'false'
      self.container.appendChild(link)
    })
  }
}
