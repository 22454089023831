import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "cameras", "modal", "reader", "start", "stop", "show" ]
  static values  = { url: String, status: String }

  connect() {
    this.element[this.identifier] = this
    $(this.modalTarget).modal('show')
  }

  beginScanning() {
    Html5Qrcode.getCameras().then(devices => {
      $(this.modalTarget).modal('hide')

      this.camerasTarget.disabled = false
      this.camerasTarget.options.length = 0
      this.startTarget.disabled = false
      this.stopTarget.disabled = false

      devices.forEach(device => {
        this.addCameraOption(device)
      })
    }).catch(err => {
      window.toastr.error(err)
    })
  }

  addCameraOption(device) {
    var option = document.createElement('option')
    option.text = device.label
    option.value = device.id
    this.camerasTarget.add(option)
  }

  start() {
    $(this.element).addClass('scanning')

    const scanCallback = (decodedText, decodedResult) => {
      this.scan(decodedText, decodedResult)
    }
    this.html5QrCode = new Html5Qrcode(this.readerTarget.id)
    this.html5QrCode
      .start(this.camerasTarget.value,
             { fps: 10, qrbox: 250 , formatsToSupport: [Html5QrcodeSupportedFormats.QR_CODE], experimentalFeatures: { useBarCodeDetectorIfSupported: true } },
             scanCallback)
      .catch((err) => {
        console.log(err)
      })
  }

  stop() {
    $(this.element).removeClass('scanning')

    if (!this.html5QrCode) { return }

    this.html5QrCode.stop().then((_ignore) => {
      this.readerTarget.innerHTML = ''
      this.html5QrCode = null
    }).catch((err) => {
      console.log(err)
    })
  }

  scan(decodedText, _decodedResult) {
    if (this.statusValue == 'working') {
      return
    }

    this.statusValue = 'working'

    const controller = this
    const url = this.urlValue + '/' + decodedText

    $.ajax({
      url: url,
      type: 'GET',
      error: function() {
        controller.statusValue = null
        window.toastr.error('An error occured when looking up this code!')
      },
      success: function(response) {
        controller.statusValue = null
        $('.modal-title', $(controller.showTarget)).html(decodedText)
        $('.modal-body', $(controller.showTarget)).html(response)
        $('.modal-body a', $(controller.showTarget)).attr('target', '_blank')
        $(controller.showTarget).modal('show')
      }
    })
  }
}
