import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "initialFeeBase",
    "initialFee",
    "initialFeeDisplay",
    "initialFees",
    "annualFeeBase",
    "annualFeeDisplay",
    "annualFeeQuantity",
    "annualFee",
    "annualFees",
    "additionalFeeBase",
    "additionalFeeDisplay",
    "additionalFeeQuantity",
    "additionalFee",
    "additionalFees",
    "discount",
    "discountRate",
    "discountPct",
    "totalFee"
  ];

  connect() {
    this.element[this.identifier] = this;

    this.boundCalculate = this.calculate.bind(this);

    this.initialFeeBaseTarget.addEventListener("change", this.boundCalculate);
    this.initialFeeTarget.addEventListener("change", this.boundCalculate);
    this.annualFeeBaseTarget.addEventListener("change", this.boundCalculate);
    this.annualFeeQuantityTarget.addEventListener("change", this.boundCalculate);
    this.annualFeeTarget.addEventListener("change", this.boundCalculate);

    if (this.hasAdditionalFeeTarget) {
      this.additionalFeeBaseTarget.addEventListener("change", this.boundCalculate);
      this.additionalFeeQuantityTarget.addEventListener("change", this.boundCalculate);
      this.additionalFeeTarget.addEventListener("change", this.boundCalculate);
    }

    this.discountTargets.forEach((discount) => {
      discount.addEventListener("change", this.boundCalculate);
    });

    // if (this.totalFeeTarget.value === "") {
    this.calculate();
    // }
  }

  calculate() {
    var initialFee = parseFloat(this.initialFeeBaseTarget.value)
    var annualFee = parseFloat(this.annualFeeBaseTarget.value) // * this.annualFeeQuantityTarget.value;
    if (this.hasAdditionalFeeTarget) {
      var additionalFee = parseFloat(this.additionalFeeBaseTarget.value) // * this.additionalFeeQuantityTarget.value;
    } else {
      var additionalFee = 0;
    }

    var discounts = 0;
    this.discountTargets.forEach((discount) => {
      if (discount.checked) {
        discounts += parseInt(discount.dataset.discount);
      }
    });
    if (discounts > 100) {
      discounts = 100;
    }
    const discountRate = 1 - discounts / 100;
    this.discountRateTarget.value = (discounts / 100).toFixed(2);
    this.discountPctTargets.forEach((target) => {
      target.innerText = discounts.toFixed(0) + "%";
    });

    this.initialFeeTarget.value = (initialFee * discountRate).toFixed(2);
    this.annualFeeTarget.value = (annualFee * discountRate).toFixed(2);
    this.initialFeesTarget.innerText = this.formatCurrency(this.initialFeeTarget.value);
    this.initialFeeDisplayTarget.innerText = this.formatCurrency(this.initialFeeTarget.value);
    this.annualFeeDisplayTarget.innerText = this.formatCurrency(this.annualFeeTarget.value);
    this.annualFeesTarget.innerText = this.formatCurrency(this.annualFeeTarget.value * this.annualFeeQuantityTarget.value);

    if (this.hasAdditionalFeeTarget) {
      this.additionalFeeTarget.value = additionalFee.toFixed(2);
      this.additionalFeeDisplayTarget.innerText = this.formatCurrency(this.additionalFeeTarget.value);
      this.additionalFeesTarget.innerText = this.formatCurrency(this.additionalFeeTarget.value * this.additionalFeeQuantityTarget.value);

      var totalFee = parseFloat(this.initialFeeTarget.value) +
                     (parseFloat(this.annualFeeTarget.value) * parseInt(this.annualFeeQuantityTarget.value)) +
                     (parseFloat(this.additionalFeeTarget.value) * parseInt(this.additionalFeeQuantityTarget.value));
    } else {
      var totalFee = parseFloat(this.initialFeeTarget.value) +
                     (parseFloat(this.annualFeeTarget.value) * parseInt(this.annualFeeQuantityTarget.value));
    }

    this.totalFeeTarget.value = totalFee.toFixed(2);
  }

  formatCurrency(number) {
    return parseFloat(number).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  }
}
