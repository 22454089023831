import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["item"]

  connect() {
    this.element[this.identifier] = this

    this.boundClickListener = this.listenerClicked.bind(this)
    this.listeners = document.querySelectorAll("[data-batch-src='#" + this.element.id + "']")
    this.listeners.forEach(listener => {
      listener.addEventListener('click', this.boundClickListener)
    })

    this.i18n = JSON.parse(this.data.get('i18n'))
    this.actionsPanel = document.querySelectorAll("[data-batch-actions-for='#" + this.element.id + "']")
  }

  disconnect() {
    this.listeners.forEach(listener => {
      listener.removeEventListener('click', this.boundClickListener)
    })
  }

  toggleActionsPanel(shown) {
    if (shown) {
      $(this.actionsPanel).pendingPanel()
    } else {
      $(this.actionsPanel).pendingPanel('close')
    }
  }

  listenerClicked(event) {
    var selected = this.selected()
    if (selected.length == 0) {
      window.toastr.error(this.i18n.empty)
      event.preventDefault()
      event.stopPropagation()
      return
    }

    // Ensure we find the A that was clicked, they may have clicked directly on the I (the icon) tag.
    var target = event.target
    if (target.tagName != 'A') {
      target = target.closest('a')
    }

    var batchParam = target.dataset.batchParam
    if (!batchParam) { batchParam = 'ids' }

    var url = new URL(target.href)
    url.searchParams.set(batchParam, selected.join())
    target.href = url.toString()
  }

  selected() {
    var collectChecked = (accumulator, current) => {
      if (current.checked) {
        accumulator.push(current.value)
      }
      return accumulator
    }

    return this.toggles().reduce(collectChecked, new Array)
  }

  toggle(event) {
    if (event.target.checked) {
      return this.toggleActionsPanel(event.target.checked)
    }
    if (this.selected().length > 0) {
      return
    } else {
      this.toggleActionsPanel(event.target.checked)
    }
  }

  toggleAll(event) {
    this.toggles().forEach(toggle => {
      toggle.checked = event.target.checked
    })

    this.toggleActionsPanel(event.target.checked)
  }

  toggles() {
    return new Array(...this.element.querySelectorAll('input[data-batch-toggle]'))
  }
}
