import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["cageType", "numberPerCageFemale", "numberPerCageMale", "animalsPerCage",
                    "cageCountFemale", "cageCountMale", "cageCountTotal"]
  static values = {
    females: Number,
    males: Number
  }

  connect() {
    this.element[this.identifier] = this
    const controller = this

    const cageTypeChangeCallback = function() {
      controller.validateAnimalsPerCage()
    }
    this.cageTypeTarget.addEventListener('change', cageTypeChangeCallback)
    this.validateAnimalsPerCage()

    const numberPerCageChangeCallback = function() {
      controller.numberPerCageChanged()
    }
    this.numberPerCageFemaleTarget.addEventListener('change', numberPerCageChangeCallback)
    this.numberPerCageMaleTarget.addEventListener('change', numberPerCageChangeCallback)
  }

  validateAnimalsPerCage() {
    var animalsPerCage = this.cageTypeTarget.options[this.cageTypeTarget.selectedIndex].dataset.animalsPerCage
    var fields = [this.numberPerCageFemaleTarget, this.numberPerCageMaleTarget]

    var exceedsMax = false

    fields.forEach(function(field) {
      if (typeof animalsPerCage !== 'undefined' && Number(field.value) > Number(animalsPerCage)) {
        $(field).closest('.form-group').addClass('has-error')
        exceedsMax = true
      }
      else {
        $(field).closest('.form-group').removeClass('has-error')
      }
    })

    $('em', $(this.animalsPerCageTarget)).text(
      this.cageTypeTarget.options[this.cageTypeTarget.selectedIndex].text
    )
    if (typeof animalsPerCage == 'undefined') {
      $('strong', $(this.animalsPerCageTarget)).text('Unlimited')
    }
    else {
      $('strong', $(this.animalsPerCageTarget)).text(animalsPerCage)
    }

    if (exceedsMax) {
      $(this.animalsPerCageTarget).removeClass('callout-success').addClass('callout-danger')
      $('.checkbox', this.animalsPerCageTarget).removeClass('not-applicable').addClass('applicable')
    }
    else {
      $(this.animalsPerCageTarget).removeClass('callout-danger').addClass('callout-success')
      $('.checkbox', this.animalsPerCageTarget).removeClass('applicable').addClass('not-applicable')
    }
  }

  numberPerCageChanged() {
    var femaleCages = 0
    var maleCages = 0

    if (this.femalesValue > 0 && this.numberPerCageFemaleTarget.value > 0) {
      femaleCages = Math.ceil(this.femalesValue / this.numberPerCageFemaleTarget.value)
    }

    if (this.malesValue > 0 && this.numberPerCageMaleTarget.value > 0) {
      maleCages = Math.ceil(this.malesValue / this.numberPerCageMaleTarget.value)
    }

    this.cageCountFemaleTarget.innerText = femaleCages
    this.cageCountMaleTarget.innerText = maleCages
    this.cageCountTotalTarget.innerText = femaleCages + maleCages

    this.validateAnimalsPerCage()
  }
}
