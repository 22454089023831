import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "dialog", "form", "toggle" ]

  connect() {
    this.element[this.identifier] = this

    if (this.element.classList.contains('open')) {
      this.show()
    }

    if (this.element.querySelector('.thought-status') != null) {
      this.element.classList.add('saved')
      this.element.querySelector('button.close').focus()
    }

    this.boundEscape = this.escape.bind(this)
    document.addEventListener('keydown', this.boundEscape)
  }

  disconnect() {
    document.removeEventListener('keydown', this.boundEscape)
  }

  show() {
    this.toggleTarget.ariaExpanded = true
    this.element.classList.add('open')
    this.element.querySelector('#thought_comment').focus()
  }

  hide() {
    this.toggleTarget.ariaExpanded = false
    this.element.classList.remove('open')
    this.element.classList.remove('saved')
    this.element.classList.add('closed')
    if (this.element.querySelector('.thought-status') != null) {
      this.element.querySelector('.thought-status').remove()
    }
    this.toggleTarget.focus()
  }

  toggle() {
    if (this.element.classList.contains('open')) {
      this.hide()
    } else {
      this.show()
    }
  }

  escape(event) {
    if (event.key === 'Escape' && this.element.contains(document.activeElement)) {
      this.hide()
    }
  }

  createHiddenInput(name, value) {
    const input = document.createElement('input')
    input.type = 'hidden'
    input.name = name
    input.value = value
    this.formTarget.appendChild(input)
  }

  submit(event) {
    event.preventDefault()

    if (document.querySelector('.page-header') != null) {
      this.createHiddenInput('thought[regarding]', document.querySelector('.page-header').innerText)
    } else {
      this.createHiddenInput('thought[regarding]', document.title)
    }
    this.createHiddenInput('thought[url]', window.location.href)
    this.createHiddenInput('thought[engine]', document.body.dataset.engine)
    this.createHiddenInput('thought[controller]', document.body.dataset.controller)
    this.createHiddenInput('thought[action]', document.body.dataset.action)

    if (document.querySelector('meta[property="fe:gid"]') != null) {
      this.createHiddenInput('thought[reference_gid]', document.querySelector('meta[property="fe:gid"]').getAttribute('content'))
    }

    this.formTarget.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }))
  }
}
