import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["projectsSelected", "projectsAvailable"]

  connect() {
    this.element[this.identifier] = this
  }

  toggle(event) {
    var tr = $(event.target).closest('tr').remove().clone()
    var dest = event.target.checked ? this.projectsSelectedTarget : this.projectsAvailableTarget
    $(dest).append(tr)
  }
}
