import { StreamActions } from "@hotwired/turbo"


// <turbo-stream action="toast" type="error" title="Hi there!" message="Hello, world"></turbo-stream>
//
StreamActions.toast = function() {
  const type = this.getAttribute("type")
  const title = this.getAttribute("title")
  const message = this.getAttribute("message")

  window.toastr[type](message, title)
}
