import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "toggle" ]

  connect() {
    this.element[this.identifier] = this

    // this.hasInactive = this.element.querySelectorAll('.is-inactive').length > 0
    this.element.querySelectorAll('.is-inactive').forEach((element) => {
      element.classList.add('d-none')
    })
  }

  toggle() {
    this.toggleTarget.getElementsByTagName('i')[0].classList.toggle('fa-toggle-on')
    this.element.querySelectorAll('.is-inactive').forEach((element) => {
      element.classList.toggle('d-none')
    })
  }
}
