import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['toggle', 'id', 'search', 'results', 'announcements']
  static values = {
    label: String,
    param: String,
    placeholder: String,
    url: String,
    chosen: Boolean,
    hasResults: Boolean
  }

  connect() {
    this.element[this.identifier] = this

    if (!this.paramValue) { this.paramValue = 'q' }

    this.update = window.debounce(this.update, 250).bind(this)

    this.listeners = []
    this.setupEventListener(this.toggleTarget, 'click', this.showMenu.bind(this))
    this.setupEventListener(this.toggleTarget, 'focus', this.toggleFocusListener.bind(this))
    this.setupEventListener(this.element, 'focusout', this.focusoutCloseListener.bind(this))
    this.setupEventListener(document, 'keydown', this.escapeCloseListener.bind(this))
    this.boundClickListener = this.clickCloseListener.bind(this)

    this.setToggleLabel()
    this.chosenValue = this.idTarget.value > 0

    // Allows the menu to receive focus when clicked, in focusoutCloseListener the menu will be the relatedTarget rather
    // than the main-content element.
    $('.typeahead-menu', this.element).attr('tabindex', -1)
  }

  disconnect() {
    document.removeEventListener('click', this.boundClickListener)
    this.removeEventListeners()
  }

  // Is the menu open?
  isOpen() {
    return $(this.element).hasClass('is-open')
  }

  chosenValueChanged() {
    if (this.chosenValue) {
      $(this.element).addClass('has-chosen')
    }
    else {
      $(this.element).removeClass('has-chosen')
    }
  }

  hasResultsValueChanged() {
    this.searchTarget.setAttribute('aria-expanded', this.hasResultsValue == true)
  }

  // Show the menu
  showMenu(event = null) {
    document.addEventListener('click', this.boundClickListener)

    // If near the bottom of the viewport, then position above the field rather than below.
    var viewportOffset = this.element.getBoundingClientRect()
    if (viewportOffset.top + 300 > window.innerHeight) {
      $('.typeahead-menu', this.element).addClass('typeahead-menu-above')
    }

    this.element.classList.add('is-open')
    $(this.searchTarget).focus()
    this.toggleTarget.ariaExpanded = true

    if (!event) { return }
    event.preventDefault()
    event.stopPropagation()
  }

  // Hide the menu
  hideMenu() {
    document.removeEventListener('click', this.boundClickListener)
    this.element.classList.remove('is-open')
    this.toggleTarget.ariaExpanded = false
  }

  setToggleLabel() {
    this.toggleTarget.ariaLabel = this.labelValue + ': ' + this.toggleTarget.innerText
  }

  // Choose the given option.
  choose(option) {
    this.activated = option
    this.chosenValue = true
    this.hideMenu()

    $(this.toggleTarget).html(option.dataset.name)
    $(this.idTarget).val(option.dataset.value)
    $(this.toggleTarget).focus()

    this.setToggleLabel()

    // Announce the selection
    this.announce('You selected ' + option.ariaLabel + '. The menu is closed.')
  }

  announce(text) {
    this.announcementsTarget.innerHTML = text
  }

  // Select the current active option, if set
  select() {
    if (this.activated) { this.choose(this.activated) }
  }

  // Set the active option which is the option currently focused upon or activated via arrow keys
  setActiveOption(option, scroll = true) {
    this.activated = option
    $('[role=listbox]', this.element).attr('aria-activedescendant', this.activated.id)
    $('[role=option]', this.element).removeClass('active')
    $('[role=option]', this.element).attr('aria-selected', 'false')
    $(this.activated).addClass('active')
    this.activated.ariaSelected = true
    this.announce(this.activated.ariaLabel)

    if (scroll) { this.activated.scrollIntoView({ behavior: 'smooth', block: 'start' }) }
  }

  // Activate the first option in the results
  activateFirstOption() {
    var option = $('[role=option]:first', this.element)[0]
    if (option) { this.setActiveOption(option) }
  }

  // Activate the option above the currently active option
  activateHigherOption() {
    if (this.activated == null) { return this.activateFirstOption() }

    var option = $(this.activated).prev('[role=option]')[0]
    if (option) { this.setActiveOption(option) }
  }

  // Activate the option below the currently active option
  activateLowerOption() {
    if (this.activated == null) { return this.activateFirstOption() }

    var option = $(this.activated).next('[role=option]')[0]
    if (option) { this.setActiveOption(option) }
  }

  // Fetch query results and update the options
  update(event) {
    if (event.key == 'Escape' || !(event instanceof InputEvent)) { return }

    this.activated = null

    if (this.searchTarget.value.length == 0) {
      return this.reset()
    }

    let params = new URLSearchParams()
    params.append(this.paramValue, this.searchTarget.value)
    params.append('via', 'typeahead')

    fetch(`${this.urlValue}?${params}`)
      .then(response => response.text())
      .then(html => this.load(html))
  }

  // Load the HTML results of the query and initialize event listeners
  load(html) {
    $(this.element).addClass('has-loaded')
    this.resultsTarget.innerHTML = html

    this.hasResultsValue = $('[role=option]', this.resultsTarget).length > 0

    self = this
    window.setTimeout(() => {
      self.announce($('[data-typeahead-announce]', self.resultsTarget).text())
    }, 1000)

    var self = this
    $('[role=option]', this.resultsTarget).on('click', function(event) {
      self.choose(this)
    })

    $('[role=option]', this.resultsTarget).on('hover', function(event) {
      self.setActiveOption(this, false)
    })

    $('[role=option]', this.resultsTarget).on('keydown', function(event) {
      if (event.key == 'Enter') {
        self.choose(this)
        event.stopPropagation()
      }
    })
  }

  // Reset the typeahead, returns to a blank state. If an event is provided then it will stop propagation.
  reset(event) {
    this.activated = null
    this.chosenValue = false
    this.toggleTarget.innerHTML = this.placeholderValue
    $(this.idTarget).val('')
    $(this.searchTarget).val('').focus()
    $(this.resultsTarget).html('')
    $(this.element).removeClass('has-loaded')
    this.setToggleLabel()

    if (!event) { return }
    event.stopPropagation()
    event.preventDefault()
  }

  // Event listeners

  // Listen for clicks that are outside of the typeahead menu and close the menu
  clickCloseListener(event) {
    if (event.srcElement && !this.element.contains(event.srcElement)) {
      this.hideMenu()
    }
  }

  // Escape closes any open typeahead menu
  escapeCloseListener(event) {
    if (event.key === 'Escape' && this.isOpen()) {
      this.hideMenu()
      this.toggleTarget.focus()
    }
  }

  // When something outside the menu receives focus close the typeahead menu
  focusoutCloseListener(event) {
    if (event.relatedTarget && !this.element.contains(event.relatedTarget)) {
      this.hideMenu()
    }
  }

  // If the toggle receives focus and the menu is open, the user has tabbed outside the menu so close it
  toggleFocusListener(event) {
    if (this.isOpen()) { this.hideMenu() }
  }

  // Key events occuring within the typeahead menu
  keyEvent(event) {
    if (event.key == 'ArrowUp') {
      this.activateHigherOption()
    }
    else if (event.key == 'ArrowDown') {
      this.activateLowerOption()
    }
    else if (event.key == 'Enter') {
      if (this.isOpen()) {
        this.select()
      }
      else {
        this.showMenu()
      }
    }
    else if (event.key == 'Backspace' && !this.isOpen()) {
      this.reset()
    }
    else {
      return
    }

    event.preventDefault()
    event.stopPropagation()
  }

  // If enter is pressed while focus is on the reset button, reset the typeahead
  resetIfEnter(event) {
    if (event.keyCode == 13) { this.reset(event) }
  }

  // Event listener management

  setupEventListener(target, event, listener) {
    target.addEventListener(event, listener)
    this.listeners.push({ target: target, event: event, listener: listener })
  }

  removeEventListeners() {
    this.listeners.forEach(config => {
      config['target'].removeEventListener(config['event'], config['listener'])
    })
  }
}
