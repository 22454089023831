import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "input", "filenames" ]

  connect() {
    this.element[this.identifier] = this
    this.input = this.element.querySelector('input[type="file"]')

    this.boundDragOverListener = this.onDragOver.bind(this)
    this.boundDragLeaveListener = this.onDragLeave.bind(this)
    this.boundDropListener = this.onDrop.bind(this)
    this.boundChangeListener = this.onChange.bind(this)

    this.element.addEventListener('dragover', this.boundDragOverListener)
    this.element.addEventListener('dragleave', this.boundDragLeaveListener)
    this.element.addEventListener('drop', this.boundDropListener)
    this.input.addEventListener('change', this.boundChangeListener)

    this.boundDeleteListener = this.toggleAttachmentDelete.bind(this)
    this.element.querySelectorAll('[data-attachment]').forEach((attachment) => {
      attachment.addEventListener('change', this.boundDeleteListener)
    })
  }

  disconnect() {
    this.element.removeEventListener('dragover', this.boundDragOverListener)
    this.element.removeEventListener('drop', this.boundDropListener)
    this.element.removeEventListener('change', this.boundChangeListener)
    this.element.removeEventListener('dragleave', this.boundDragLeaveListener)
    this.element.querySelectorAll('[data-attachment]').forEach((attachment) => {
      attachment.removeEventListener('change', this.boundDeleteListener)
    })
  }

  onDragOver(event) {
    event.preventDefault()
    this.element.classList.add('dragover')
  }

  onDragLeave(event) {
    event.preventDefault()
    this.element.classList.remove('dragover')
  }

  onDrop(event) {
    // Prevent default behavior (Prevent file from being opened)
    event.preventDefault()
    this.element.classList.remove('dragover')

    const dataTransfer = new DataTransfer()
    if (event.dataTransfer.items) {
      ([...event.dataTransfer.items]).forEach((item, _i) => {
        if (item.kind === "file") {
          dataTransfer.items.add(item.getAsFile())
        }
      })
    } else {
      for (let i = 0; i < files.length; i++) {
        dataTransfer.items.add(files[i])
      }
    }

    this.input.files = dataTransfer.files
    this.onChange(event)
  }

  onChange(_event) {
    if (this.input.files.length == 0) {
      this.element.classList.remove('has-files')
    }
    else {
      this.element.classList.add('has-files')
    }
    this.filenamesTarget.innerHTML = [...this.input.files].map(file => file.name).join('<br>')
  }

  toggleAttachmentDelete(event) {
    event.preventDefault()

    event.target.querySelector('i').classList.toggle('fa-check-circle-o')
    event.target.querySelector('i').classList.toggle('fa-circle-o')

    var attachment = event.target.closest('[data-attachment]')
    attachment.classList.toggle('file-to-delete')

    var input = attachment.querySelector('input[type="checkbox"]')
    input.checked = !input.checked
  }
}
