import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['newer', 'older']

  connect() {
    this.element[this.identifier] = this;
    this.render();
  }

  async render() {
    if (typeof window.HtmlDiff === 'undefined') {
      this.initializeScriptTag('htmldiff-js');
    }
    while (typeof window.HtmlDiff === 'undefined') {
      await new Promise(r => setTimeout(r, 100));
    }
    this.renderDiff();
  }

  initializeScriptTag(id) {
    if (!document.getElementById(id)) {
      let js = document.createElement('script');
      js.id = id;
      js.src = document.querySelector('meta[name=' + id + ']').getAttribute('value');
      document.head.appendChild(js);
    }
  }

  renderDiff() {
    let newerItems = this.elements(this.newerTarget);
    let olderItems = this.elements(this.olderTarget);

    // Iterate through the elements and compare them
    let newerElements = newerItems.shift();
    let olderElements = olderItems.shift();
    this.diffPairs(newerElements, olderElements);

    // Iterate through the collections and compare them
    let newerCollections = new Map(newerItems.map(el => [el[0].getAttribute('data-mtf-container'), el]));
    let olderCollections = new Map(olderItems.map(el => [el[0].getAttribute('data-mtf-container'), el]));
    let keys = new Set([...newerCollections.keys(), ...olderCollections.keys()]);
    Array.from(keys).map(key => [newerCollections.get(key), olderCollections.get(key)]).forEach((els) => {
      let [newerItem, olderItem] = els;
      if (newerItem && olderItem) {
        this.diffPairs(newerItem.slice(1), olderItem.slice(1));
      }
      else if (newerItem) {
        newerItem[0].classList.add('diff-added');
      }
      else if (olderItem) {
        olderItem[0].classList.add('diff-removed');
      }
    });

    this.element.dispatchEvent(new CustomEvent('htmldiff:rendered', { bubbles: true, detail: { controller: this } }));
  }

  diffPairs(newerElements, olderElements, key = 'data-mtf-element') {
    let newerMap = new Map(newerElements.map(el => [el.getAttribute(key), el]));
    let olderMap = new Map(olderElements.map(el => [el.getAttribute(key), el]));
    let keys = new Set([...newerMap.keys(), ...olderMap.keys()]);
    Array.from(keys).map(key => [newerMap.get(key), olderMap.get(key)]).forEach((els) => this.renderDiffEls(...els));
  }

  renderDiffEls(newerEl, olderEl) {
    if (newerEl && olderEl) {
      if (newerEl.tagName === 'TR' && this.answerChanged(newerEl, olderEl, true)) {
        newerEl.classList.add('diff-changed');
        olderEl.classList.add('diff-changed');
        this.htmldiff(newerEl, olderEl);
        return;
      }

      if (newerEl.querySelector('table')) {
        let newerRows = Array.from(newerEl.querySelectorAll('tr[data-diff-key]'));
        let olderRows = Array.from(olderEl.querySelectorAll('tr[data-diff-key]'));

        let matchedRows = newerRows.map(newerRow => {
          let diffKey = newerRow.getAttribute('data-diff-key');
          let matchingOlderRow = olderRows.find(olderRow => olderRow.getAttribute('data-diff-key') === diffKey);
          return [newerRow, matchingOlderRow];
        });

        let unmatchedRows = olderRows.filter(olderRow => !newerRows.some(newerRow => newerRow.getAttribute('data-diff-key') === olderRow.getAttribute('data-diff-key')));
        unmatchedRows.forEach(olderRow => { matchedRows.push([null, olderRow]); });
        matchedRows.forEach((els) => this.renderDiffEls(...els));

        if (newerEl.dataset.elementName === undefined) {
          return;
        }

        if (newerEl.querySelector('.diff-changed, .diff-added, .diff-removed') || olderEl.querySelector('.diff-changed, .diff-added, .diff-removed')) {
          newerEl.classList.add('diff-changed');
          olderEl.classList.add('diff-changed');
        }
        return;
      }

      if (newerEl.querySelector('[data-diff-key]')) {
        let newerItems = Array.from(newerEl.querySelectorAll('[data-diff-key]'));
        let olderItems = Array.from(olderEl.querySelectorAll('[data-diff-key]'));
        return this.diffPairs(newerItems, olderItems, 'data-diff-key');
      }

      if (this.answerChanged(newerEl, olderEl)) {
        newerEl.classList.add('diff-changed');
        olderEl.classList.add('diff-changed');

        if (newerEl.classList.contains('mtf-el-text_line') || newerEl.classList.contains('mtf-el-text_paragraph') || newerEl.classList.contains('mtf-el-text')) {
          return this.htmldiff(newerEl, olderEl);
        }
      }
    }
    else if (newerEl) {
      newerEl.classList.add('diff-added');
    }
    else {
      olderEl.classList.add('diff-removed');
    }
  }

  htmldiff(newerEl, olderEl) {
    if (newerEl.querySelector('.mtf-el-control')) {
      newerEl = newerEl.querySelector('.mtf-el-control');
      olderEl = olderEl.querySelector('.mtf-el-control');
    }
    let diff = window.HtmlDiff.execute(olderEl.innerHTML, newerEl.innerHTML);
    diff = diff.replace(/class=["']diff(mod|ins|del)["']/g, 'class="diffmod"');
    newerEl.innerHTML = diff.replace(/<del(.*?)<\/del>/gs, '').replace(/<\/ins>\s*<ins class="diffmod">/g, ' ');
    olderEl.innerHTML = diff.replace(/<ins(.*?)<\/ins>/gs, '').replace(/<\/del>\s*<del class="diffmod">/g, ' ');
  }

  answerChanged(newerEl, olderEl, innerTextOnly = false) {
    if (newerEl.querySelector('.mtf-el-control')) {
      newerEl = newerEl.querySelector('.mtf-el-control');
      olderEl = olderEl.querySelector('.mtf-el-control');
    }

    if (newerEl.dataset.mtfValue !== undefined && olderEl.dataset.mtfValue !== undefined) {
      return newerEl.dataset.mtfValue !== olderEl.dataset.mtfValue;
    }
    if (!newerEl || !olderEl) {
      return false;
    }
    if (newerEl.innerText.trim() !== olderEl.innerText.trim()) {
      return true;
    }
    if (innerTextOnly) { return false; }

    if (newerEl.innerHTML !== olderEl.innerHTML) {
      return true;
    }

    return false;
  }

  // Categorize elements into collections and other elements
  elements(source) {
    const elements = Array.from(source.querySelectorAll('[data-mtf-element]:not(.mtf-section):not(.mtf-el-group):not(.mtf-el-collection):not(.mtf-el-note)'));

    // Initialize arrays to hold categorized elements
    const containers = [];
    const otherElements = [];

    // Iterate through the elements
    elements.forEach(element => {
      if (element.classList.contains('mtf-collection-item')) {
        let collection = []
        collection.push(element);

        // Also add all child elements to the collectionItems array
        const childElements = Array.from(element.querySelectorAll('[data-mtf-element]:not(.mtf-section):not(.mtf-el-group):not(.mtf-el-note)'));
        collection.push(...childElements);
        containers.push(collection);
      } else {
        // Check if the element is within an '.mtf-collection-item'
        let isChildOfCollectionItem = false;
        let parentElement = element.parentElement;

        while (parentElement) {
          if (parentElement.classList.contains('mtf-collection-item')) {
            isChildOfCollectionItem = true;
            break;
          }
          parentElement = parentElement.parentElement;
        }
        // If it's not a child of a '.mtf-collection-item', add it to the otherElements array
        if (!isChildOfCollectionItem) {
          otherElements.push(element);
        }
      }
    });

    containers.unshift(otherElements);
    return containers;
  }
}
