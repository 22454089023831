import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['menu', 'toggle']

  connect() {
    this.element[this.identifier] = this

    this.boundClickOut = this.clickOut.bind(this)
    this.boundKeyDown = this.keyDown.bind(this)

    this.menuTarget.querySelectorAll('a').forEach((element) => {
      element.setAttribute('tabindex', '-1')
    })

    // this.menuTarget.ariaExpanded = false
    this.isOpen = false
  }

  disconnect() {
    this.element[this.identifier] = undefined
    this.menuTarget.removeEventListener('click', this.boundClickOut)
    this.menuTarget.removeEventListener('keydown', this.boundKeyDown)
  }

  clickOut(event) {
    if (this.isOpen && event.target.closest('.fe-vertical-menu') === null) {
      this.hide(event)
    }
  }

  keyDown(event) {
    if (event.key === 'Escape') {
      this.hide(event)
    }
  }

  show() {
    this.isOpen = true
    // this.toggleTarget.ariaExpanded = true
    // this.menuTarget.ariaExpanded = true
    this.menuTarget.ariaHidden = false
    this.menuTarget.addEventListener('click', this.boundClickOut)
    this.menuTarget.addEventListener('keydown', this.boundKeyDown)
    this.menuTarget.classList.add('in')
    this.menuTarget.querySelectorAll('a').forEach((element) => {
      element.setAttribute('tabindex', '0')
    })
    this.menuTarget.setAttribute('tabindex', '0')
    this.menuTarget.querySelector('a:first-of-type').focus()
    // this.menuTarget.focus()
    document.querySelector('body').classList.add('modal-open')
  }

  hide(event) {
    this.isOpen = false
    event.preventDefault()

    document.querySelector('body').classList.remove('modal-open')
    // this.toggleTarget.ariaExpanded = false
    this.menuTarget.removeEventListener('click', this.boundClickOut)
    this.menuTarget.removeEventListener('keydown', this.boundKeyDown)
    // this.menuTarget.ariaExpanded = false
    this.menuTarget.ariaHidden = true
    this.menuTarget.classList.remove('in')
    this.menuTarget.querySelectorAll('a').forEach((element) => {
      element.setAttribute('tabindex', '-1')
    })
    this.menuTarget.setAttribute('tabindex', '-1')
    this.toggleTarget.focus()
  }
}
