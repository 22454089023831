import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.element[this.identifier] = this;
    this.reviewable = document.getElementById(this.element.dataset.reviewable);
    this.relocate();
  }

  // Relocate comments from their original element to the motif element they are commenting on.
  //
  relocate() {
    const comments = this.element.getElementsByClassName('reviewer-comment');
    Array.from(comments).forEach((comment) => {
      const elementId = comment.dataset.elementId;
      const itemId = comment.dataset.itemId;

      // Find the motif element the comment is commenting on.
      const selector = `#${itemId ? `item_${itemId}_` : ''}motif_element_${elementId}`;
      const element = this.reviewable.querySelector(selector);

      // If the element is not found, log an error and do nothing.
      if (!element) { console.log(`Element not found: ${selector}`); return; }

      // If the element does not have a reviewer-comments div, create one.
      if (!element.querySelector(':scope > .reviewer-comments')) {
        const reviewerCommentsDiv = document.createElement('div');
        reviewerCommentsDiv.classList.add('reviewer-comments');

        // Create a header for the reviewer-comments div. This header is the same as the comment's header.
        // Some elements have multiple comments so this prevents the header from being repeated.
        const header = comment.querySelector('header').innerHTML;
        const headerDiv = document.createElement('h4');
        headerDiv.innerHTML = header;
        headerDiv.classList.add('sr-only');
        reviewerCommentsDiv.appendChild(headerDiv);

        // If the element is a mtf-section, insert the reviewer-comments div after the header. Otherwise, append it.
        if (element.classList.contains('mtf-section')) {
          element.querySelector(':scope > .mtf-section-header').insertAdjacentElement('afterend', reviewerCommentsDiv);
        } else {
          element.appendChild(reviewerCommentsDiv);
        }
      }
      // Append the comment to the element's reviewer-comments div.
      element.querySelector(':scope > .reviewer-comments').appendChild(comment);
    });

    // Show the element if it has any remaining comments that could not be relocated.
    if (this.element.querySelector('.reviewer-comment')) {
      this.element.classList.remove('d-none');
    }
  }
}
