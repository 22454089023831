import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "body" ]

  connect() {
    this.element[this.identifier] = this
    this.parent = this.element.closest('.reviewable')

    this.render()

    this.boundUpdate = window.debounce(this.update, 100).bind(this)
    this.observer = new MutationObserver(this.boundUpdate)
    this.observer.observe(this.parent, { childList: true, subtree: true })
  }

  disconnect() {
    this.observer.disconnect()
  }

  update(mutations) {
    var hasUpdates = false
    for (var i = 0; i < mutations.length; i++) {
      if (mutations[i].target.classList.contains('reviewer-comments')) {
        hasUpdates = true
        break
      }
    }
    if (hasUpdates) {
      this.render()
    }
  }

  render() {
    self = this
    this.bodyTarget.innerHTML = ''
    var comments = this.parent.querySelectorAll('.reviewer-comment:not(.reviewer-comment-resolved)')
    var commentIds = []
    comments.forEach(function(comment) {
      const elementId = comment.dataset.elementId
      const itemId = comment.dataset.itemId

      // Link to the motif element, and if not found, link to the comment itself.
      // var selector = `#${itemId ? `item_${itemId}_` : ''}${elementId}`
      var selector = `#${itemId ? `item_${itemId}_` : ''}motif_element_${elementId}`
      if (document.querySelector(selector) === null) { selector = `#${comment.id}` }

      // Ensure only one link per element.
      if (commentIds.includes(selector)) { return }
      commentIds.push(selector)

      var link = document.createElement('a')
      link.href = selector
      link.innerHTML = comment.dataset.elementName
      link.dataset.turbo = 'false'
      var item = document.createElement('li')
      item.className = 'mini-list-item'
      item.appendChild(link)
      self.bodyTarget.appendChild(item)
    })

    if (commentIds.length > 0) {
      this.element.classList.remove('d-none')
    }
    else {
      this.element.classList.add('d-none')
    }
  }
}
