import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ 'video', 'progress', 'playPause', 'timer', 'nextModuleForm' ];
  static values = { playing: Boolean, complete: Boolean };

  connect() {
    this.element[this.identifier] = this;

    this.videoTarget.controlsList = 'noplaybackrate nodownload';

    this.playingValue = false;
    this.maxPlayTime = 0.0;

    this.boundProgressLoop = this.progressLoop.bind(this);
    this.boundPlayPause = this.playPause.bind(this);
    this.boundPlayPauseVideo = this.playPauseVideo.bind(this);
    this.boundNextVideo = this.nextVideo.bind(this);
    this.boundOnSeek = this.onSeek.bind(this);
    // this.boundOnSeeked = this.onSeeked.bind(this);

    this.videoTarget.addEventListener('loadedmetadata', this.boundProgressLoop);
    this.videoTarget.addEventListener('timeupdate', this.boundProgressLoop);

    this.playPauseTarget.addEventListener('click', this.boundPlayPause);

    this.videoTarget.addEventListener('play', this.boundPlayPauseVideo);
    this.videoTarget.addEventListener('pause', this.boundPlayPauseVideo);
    this.videoTarget.addEventListener('ended', this.boundNextVideo);

    // If complete, do not prevent seeking.
    if (this.completeValue) {
      this.nextVideo();
    } else {
      this.videoTarget.addEventListener('seeking', this.boundOnSeek);
      // this.videoTarget.addEventListener('seeked', this.boundOnSeeked);
    }
  }

  disconnect() {
    this.videoTarget.removeEventListener('loadedmetadata', this.boundProgressLoop);
    this.videoTarget.removeEventListener('timeupdate', this.boundProgressLoop);

    this.playPauseTarget.removeEventListener('click', this.boundPlayPause);

    this.videoTarget.removeEventListener('play', this.boundPlayPauseVideo);
    this.videoTarget.removeEventListener('pause', this.boundPlayPauseVideo);
    this.videoTarget.removeEventListener('seeking', this.boundOnSeek);
    this.videoTarget.removeEventListener('ended', this.boundNextVideo);
  }

  nextVideo() {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = 't';
    input.value = new Date().getTime();
    this.nextModuleFormTarget.appendChild(input);
    Array.from(this.nextModuleFormTarget.getElementsByTagName('button')).forEach((button) => {
      button.disabled = false;
    });
  }

  progressLoop() {
    if (this.isPlaying() && this.videoTarget.currentTime > this.maxPlayTime) {
      this.maxPlayTime = this.videoTarget.currentTime;
    }

    if (isNaN(this.videoTarget.duration)) {
      return;
    }

    this.progressTarget.value = Math.round((this.videoTarget.currentTime / this.videoTarget.duration) * 100);
    // this.progressTarget.style.width = this.progressTarget.value + "%";
    this.progressTarget.innerHTML = this.progressTarget.value + "%";

    const minutes = Math.floor(this.videoTarget.currentTime / 60);
    const seconds = Math.floor(this.videoTarget.currentTime % 60);
    const formattedTime = `${minutes}:${seconds.toString().padStart(2, '0')}`;

    const totalTime = `${Math.floor(this.videoTarget.duration / 60)}:${Math.floor(this.videoTarget.duration % 60).toString().padStart(2, '0')}`;
    this.timerTarget.innerHTML = `${formattedTime} / ${totalTime}`;
  }

  playPause() {
    if (this.videoTarget.paused) {
      this.videoTarget.play();
    } else {
      this.videoTarget.pause();
    }
  }

  playPauseVideo() {
    this.playingValue = !this.playingValue;

    const icon = this.playPauseTarget.getElementsByClassName('fa')[0];
    if (this.videoTarget.paused) {
      this.playPauseTarget.innerHTML = '<i class="fa fa-play"></i> Play';
    } else {
      this.playPauseTarget.innerHTML = '<i class="fa fa-pause"></i> Pause';
    }
  }

  playingValueChanged() {
    if (this.playingValue) {
      this.element.classList.add('playing');
    } else {
      this.element.classList.remove('playing');
    }
  }

  isPlaying() {
    return this.videoTarget.currentTime > 0 && !this.videoTarget.paused && !this.videoTarget.ended;
  }

  onSeek() {
    this.videoTarget.pause();

    // guard agains infinite recursion:
    // user seeks, seeking is fired, currentTime is modified, seeking is fired, current time is modified, ....
    var delta = this.videoTarget.currentTime - this.maxPlayTime;
    if (delta > 0.01) {
      this.videoTarget.currentTime = this.maxPlayTime;
      return;
    }

    this.videoTarget.play();
  }

  // onSeeked() {
  //   console.log('onSeeked', this.videoTarget.currentTime, this.maxPlayTime);
  // }
}
